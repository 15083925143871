<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix flex">
        <div>
            <span>词条类型：</span>
            <el-select v-model="type" clearable placeholder="请选择词条类型" @change="SearchComprehensiveType">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="mr20 flex_1"><el-button style="float: right" type="primary" @click="opendialog()">新增词条</el-button></div>
      </div>
      <!--添加-->
      <el-dialog :title="form._id ? '编辑词条' : '新增词条'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
                <el-form-item label="词条类型" prop="type">
                    <el-select v-model="form.type" placeholder="请选择词条类型">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否显示">
                  <el-switch v-model="form.isshow"></el-switch>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input type="text" placeholder="请填写词条标题" v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input type="textarea" placeholder="多个内容请用中文的'，'隔开" v-model="form.content"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clocedialog()">取 消</el-button>
                <el-button type="primary" v-if="!form._id" @click="createComprehensive(form)">添 加</el-button>
                <el-button type="primary" v-if="form._id" @click="editComprehensive(form)">更 新</el-button>
            </div>
        </el-dialog>
      <!--添加-->
      <el-table
        :data="items"
        style="width: 100%; margin-bottom: 20px"
        row-key="_id"
        border
      >
        <el-table-column type="index" width="55"> </el-table-column>
        <el-table-column prop="type" label="词条类型">
            <template slot-scope="scope">
                {{ showtype(scope.row.type) }}
            </template>
        </el-table-column>
        <el-table-column prop="title" label="词条标题"></el-table-column>
        <el-table-column show-overflow-tooltip prop="content" label="词条内容"></el-table-column>
        <el-table-column label="是否显示">
            <template slot-scope="scope">
                {{ scope.row.isshow ? '是' : '否' }}
            </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建人"></el-table-column>
        <el-table-column prop="editor" label="修改人"></el-table-column>
        <el-table-column prop="createdAt" label="创建时间" :formatter="formatTime"></el-table-column>
        <el-table-column prop="updatedAt" label="修改时间" :formatter="formatTime"></el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleedit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </template>
  <script>
  export default {
    data() {
      return {
        formLabelWidth: "120px",
        dialogFormVisible: false,
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页数据量
        total: 0, // 总数据量
        items: [],
        timeslot:"",
        search: "",
        searchdrug: "",
        searchdate: "",
        type: "",
        form: {
          _id:"",
          title:"",
          type: "",
          isshow:true,
          content:"",
          creator:"",
          editor:"",
          createdAt:"",
          updatedAt:"",
        },
        options: [{
          value: 'chiefcomplaint',
          label: '主诉'
        }, {
          value: 'historyofpresentillness',
          label: '现病史'
        }, {
          value: 'pasthistory',
          label: '既往史'
        }, {
          value: 'allergyhistory',
          label: '过敏史'
        }, {
          value: 'palpationanddiagnosis',
          label: '望闻切诊'
        }, {
          value: 'inspect',
          label: '检查'
        }, {
          value: 'diagnosis',
          label: '诊断'
        }, {
          value: 'discriminate',
          label: '辨证'
        }, {
          value: 'exhort',
          label: '医嘱'
        }],
        rules: {
          title: [
            { required: true, message: '请输入词条标题', trigger: 'blur' },
          ],
          type: [
            { required: true, message: '请选择词条类型', trigger: 'change' }
          ],
          content: [
            { required: true, message: '请填写内容形式，多个词组可以逗号“，”隔开', trigger: 'blur' }
          ],
        }
      };
    },
    computed: {},
    methods: {
      showtype(value){
        const selectedOption = this.options.find(option => option.value === value);
        return selectedOption.label
      },  
      clocedialog(){
        this.dialogFormVisible = false;
      },
      opendialog(){
        this.dialogFormVisible = true;
        this.form = {}
      },
      SearchComprehensiveType(val){
        if (val === '') {
          this.loadItems()
        }else{
          const data = {
            currentPage: this.currentPage,
            pageSize:this.pageSize,
            type:val
          }
          this.$api.templates.SearchComprehensiveType(data).then(res =>{
            this.currentPage = res.data.pagination.currentPage;
            this.pageSize = res.data.pagination.pageSize;
            this.total = res.data.pagination.total;
            this.items = res.data.data;
          })
        }
      },
      editComprehensive(data) {
        this.$api.templates.editComprehensive(data).then(res => {
            if(res.data.code === 200){
                this.loadItems();
            }
            this.$message({
                message: res.data.msg,
                type: "success",
            });
            this.dialogFormVisible = false;
        });
      },
      handleedit(data) {
        this.dialogFormVisible = true;
        this.form = JSON.parse(JSON.stringify(data))
      },
      handleDelete(data) {
        this.$api.templates.deleteComprehensive(data).then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.loadItems();
        });
      },
      createComprehensive(data) {
        this.$refs.form.validate((valid) => {
            if (valid) {
              this.$api.templates.createComprehensive(data).then((res) => {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.loadItems();
              });
            } else {
              this.$message({
                  message: "请按规则填写",
                  type: "warning",
              });
              return false;
            }
        });
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      loadItems() {
        const data = {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        };
        this.$api.templates.Comprehensivelist(data).then((res) => {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        });
      },
      formatTime(row,column){
          let data = row[column.property]
          let dtime = new Date(data)
          const year = dtime.getFullYear()
          let month = dtime.getMonth() + 1
          if (month < 10) {
              month = '0' + month
          }
          let day = dtime.getDate()
          if (day < 10) {
              day = '0' + day
          }
          let hour = dtime.getHours()
          if (hour < 10) {
              hour = '0' + hour
          }
          let minute = dtime.getMinutes()
          if (minute < 10) {
              minute = '0' + minute
          }
          let second = dtime.getSeconds()
          if (second < 10) {
              second = '0' + second
          }
          return year+ '-' + month+ '-' + day
        }
    },
    created() {
      this.loadItems();
    },
  };
  </script>
  <style>
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 250px;
  }
  .w250{
    width: 250px;
  }
  .w120{
    width: 120px;
  }
  .flex{
    display: flex;
  }
  .flex_1{
    flex:1;
  }
  .justifyend{
    justify-content: end;
  }
  button:focus {
      outline: none;
  }
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  </style>
  